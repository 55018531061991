<template>
  <div class="app-holder">
    <div>
      <renderer
        @move_in="moveIn"
        :template="template"
        :id="property.id"
        :rando="rando"
        :company_id="property.company_id"
        :name="property.name"
        :number="property.number"
        :unit_count="property.unit_count"
        :lease_count="property.lease_count"
        v-if="template" />
      <div v-else>
        <async-loader></async-loader>
      </div>
    </div>
  </div>

</template>
<script>
    import AsyncLoader from '../includes/Loading.vue';
    import renderer from "../../Renderer.js"
    import { mapGetters } from 'vuex';
    import { EventBus } from '../../EventBus.js';

    export default {
        data() {
            return {
                template: null,
                loaded: false,
                rando: 1
            }
        },
        components: {
            renderer,
            AsyncLoader
        },
        computed: {
            ...mapGetters({
                filtered: 'propertiesStore/filtered',
            }),
            property(){
                return this.filtered[0];
            }
        },
        async created(){
            this.loadComponent();
        },
        methods: {
          increment(){
              this.rando++;
          },
          async loadComponent(){
              this.template = null;
              let extensions = this.$store.getters['authenticationStore/getExtensionPoints'].facility;

              let ext = extensions.find(e => e.id === this.$route.params.gds_id );
              console.log("ext.component", ext.component)
              if(ext.component){
                this.template = ext.component;
                this.loaded = true;
              }


              // let url = ext.component;
              // let component_response = await this.$http.get(
              //     url,
              //     {
              //         headers: {
              //             'X-storageapi-key': '309365e7685b4b048d79dc7d29bd4f57',
              //             'X-storageapi-date': '1588187243'
              //         }
              //     });
              // this.template = component_response.body;
              // this.loaded = true;
          },
          async moveIn(unit){
              console.log("unit", unit);
                await this.$store.dispatch('onBoardingStore/getUnitInfo', {unit_id: unit.id});
                // let u = await this.$store.getters('onBoardingStore/unit');
                await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.property);
                EventBus.$emit('new_lead');
          }

        },
        watch:{
            "$route.params.gds_id"(){
                this.loadComponent();
            },

        }
    }
</script>

<style scoped>

  .app-holder{
    flex: 1
  }

</style>